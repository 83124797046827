define("ember-bootstrap/templates/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OzpuAZKq",
    "block": "{\"symbols\":[\"@onClose\",\"@title\",\"&default\"],\"statements\":[[6,[37,1],[[28,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,[[30,[36,2],null,[[\"title\",\"close\"],[[30,[36,0],[[32,0,[\"titleComponent\"]]],null],[30,[36,0],[[32,0,[\"closeComponent\"]]],[[\"onClick\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[6,[37,0],[[32,0,[\"titleComponent\"]]],null,[[\"default\"],[{\"statements\":[[1,[32,2]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,0,[\"closeButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"closeComponent\"]]],[[\"onClick\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs"
    }
  });
  _exports.default = _default;
});