define("ember-math-helpers/helpers/min", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.min = min;
  /**
   * Takes two or more numbers, and finds the min of the set using `Math.min`
   *
   * ```hbs
   * {{min a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.min`
   * @return {number} The min of the set of numbers
   */
  function min(numbers) {
    return Math.min.apply(Math, (0, _toConsumableArray2.default)(numbers));
  }
  var _default = Ember.Helper.helper(min);
  _exports.default = _default;
});