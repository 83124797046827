define("ember-local-settings/adapters/local-memory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Adapter that stores values in an in-memory hash.
   *
   * @class LocalMemoryAdapter
   * @exten extends Ember.Object
   */
  var LocalMemoryAdapter = Ember.Object.extend({
    /**
     * The storage hash (in the config so it's shared across all instances)
     *
     * @property storage
     * @private
     * @type Object
     */
    storage: Ember.computed.alias('config.localMemoryStorage'),
    /**
     * Get a value
     *
     * @param {String} key
     * @returns {String} value
     */
    getValue: function getValue(key) {
      return this.get('storage')[key];
    },
    /**
     * Set a value
     *
     * @param {String} key
     * @param {String} value
     * @return {Void}
     */
    setValue: function setValue(key, value) {
      this.get('storage')[key] = value;
    },
    /**
     * Delete a value
     *
     * @param {String} key
     * @return {Void}
     */
    deleteValue: function deleteValue(key) {
      delete this.get('storage')[key];
    },
    /**
     * Get count of stored values
     *
     * @returns {Integer}
     */
    getItemCount: function getItemCount() {
      return this.getKeys().length;
    },
    /**
     * Get the key of the stored value at an index
     *
     * @param {Integer} index
     * @returns {String}
     */
    getKeyAt: function getKeyAt(index) {
      return this.getKeys()[index];
    },
    /**
     * Get an array of the keys of all the stored values
     *
     * @returns {Array}
     */
    getKeys: function getKeys() {
      return Object.keys(this.get('storage'));
    },
    init: function init() {
      this._super.apply(this, arguments);

      // Make sure we have a storage hash
      if (!this.get('storage')) {
        this.set('storage', {});
      }
    }
  });
  var _default = LocalMemoryAdapter;
  _exports.default = _default;
  LocalMemoryAdapter.reopenClass({
    toString: function toString() {
      return "ember-local-settings/adapters/local-memory";
    },
    clearStorage: function clearStorage() {
      LocalMemoryAdapter.prototype.storage = {};
    }
  });
});