define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PohR8Zr0",
    "block": "{\"symbols\":[\"@scrollable\",\"@centered\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"modal-dialog \",[32,0,[\"sizeClass\"]],\" \",[30,[36,0],[[32,2],\"modal-dialog-centered\"],null],\" \",[30,[36,0],[[32,1],\"modal-dialog-scrollable\"],null]]]],[14,\"role\",\"document\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"modal-content\"],[4,[38,2],null,[[\"focusTrapOptions\"],[[30,[36,1],null,[[\"initialFocus\",\"clickOutsideDeactivates\"],[[32,0,[\"initialFocus\"]],true]]]]]],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\",\"focus-trap\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs"
    }
  });
  _exports.default = _default;
});