define("ember-math-helpers/helpers/ceil", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ceil = ceil;
  _exports.default = void 0;
  /**
   * Executes `Math.ceil` on the number passed to the helper.
   *
   * ```hbs
   * {{ceil a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.ceil`
   * @return {number} The ceil of the passed number
   */
  function ceil(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      number = _ref2[0];
    return Math.ceil(number);
  }
  var _default = Ember.Helper.helper(ceil);
  _exports.default = _default;
});