define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xkoXZNRU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[30,[36,0],[[32,0,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"_disabled\",\"_readonly\",\"onChange\",\"_onChange\"],[[32,0,[\"model\"]],[32,0,[\"formLayout\"]],[32,0,[\"horizontalLabelGridClass\"]],[32,0,[\"showAllValidations\"]],[32,0,[\"disabled\"]],[32,0,[\"readonly\"]],[32,0,[\"elementChanged\"]],[32,0,[\"resetSubmissionState\"]]]]],[30,[36,0],[[32,0,[\"groupComponent\"]]],[[\"formLayout\"],[[32,0,[\"formLayout\"]]]]],[32,0,[\"isSubmitting\"]],[32,0,[\"isSubmitted\"]],[32,0,[\"isRejected\"]],[32,0,[\"resetSubmissionState\"]],[32,0,[\"doSubmit\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });
  _exports.default = _default;
});