define("ember-local-storage/local/array", ["exports", "ember-local-storage/mixins/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.ArrayProxy.extend(_array.default, {
    _storageType: 'local'
  });
  _exports.default = _default;
});