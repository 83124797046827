define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "smKFVnCt",
    "block": "{\"symbols\":[\"@dropdownElementId\",\"@class\",\"@toggleElement\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"_isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-popper\",[[24,\"tabindex\",\"-1\"],[17,4]],[[\"@id\",\"@class\",\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[31,[[32,1],\"__menu\"]],[31,[\"dropdown-menu \",[32,0,[\"alignClass\"]],\" \",[30,[36,0],[[32,0,[\"isOpen\"]],\"show\"],null],\" \",[32,2]]],[32,0,[\"popperPlacement\"]],[32,3],[32,0,[\"_renderInPlace\"]],[32,0,[\"destinationElement\"]],[32,0,[\"popperModifiers\"]],[32,0,[\"setFocus\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,5,[[30,[36,2],null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[30,[36,1],[[32,0,[\"itemComponent\"]]],null],[30,[36,1],[[32,0,[\"linkToComponent\"]]],null],[30,[36,1],[[32,0,[\"linkToComponent\"]]],null],[30,[36,1],[[32,0,[\"dividerComponent\"]]],null]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });
  _exports.default = _default;
});