define("ember-inputmask/components/currency-input", ["exports", "ember-inputmask/components/input-mask"], function (_exports, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * `{{currency-input}}` component.
   *
   * Displays an input that masks to currency
   *
   * @class CurrencyInput
   */
  var _default = _inputMask.default.extend({
    oldComponent: '{{currency-input}}',
    newComponent: '{{one-way-currency-mask}}',
    mask: 'currency'
  });
  _exports.default = _default;
});