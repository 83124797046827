define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de", {}], ["en", {
    "about_us": "About us",
    "all_rights_reserved": "All rights reserved",
    "blog": "Blog",
    "business": "Business",
    "buttons": {
      "activate": "Activate",
      "archive": "Archive",
      "comment": "Comment",
      "delete": "Delete",
      "edit": "Edit",
      "subscribe": "Subscribe"
    },
    "careers": "Careers",
    "community": "Community",
    "company": "Company",
    "contacts": "Contacts",
    "design": "Design",
    "development": "Development",
    "digitalisation": "Digitalisation",
    "docs": "Documentation",
    "entity": {
      "activate_confirm": "Activate entity \"{title}\" till {date}?",
      "activate_months": "{months, plural, =1 {1 month} other {# months}}",
      "custom_date": "Custom date"
    },
    "error": {
      "occured": "An error occured!"
    },
    "events": "Events",
    "feedback": "Feedback",
    "foo": "The {product} costs {price, number, USD}",
    "invoice": {
      "status": {
        "opened": "Opened",
        "payed": "Payed"
      }
    },
    "language": "Language",
    "languages": "Languages",
    "legal": "Legal",
    "mail": "Mail",
    "marketing": "Marketing",
    "modal": {
      "confirm": "Do you confirm operation?"
    },
    "nav": {
      "sites": "Sites"
    },
    "neuronet": "Neuronet",
    "order": {
      "status": {
        "accepted": "In progress",
        "draft": "New",
        "finished": "Done",
        "opened": "Open to offers"
      }
    },
    "our_updates": "our updates and special offers",
    "payments": "Payments",
    "portfolio": {
      "buttons": {
        "view": "View"
      },
      "header": {
        "content1": "We Are A Team Of Developers",
        "content2": "We Create Websites",
        "content3": "and mobile apps",
        "share": "share with friends"
      },
      "title": "Portfolio"
    },
    "pricing": "Pricing",
    "privavy_policy": "Privacy policy",
    "products": "Products",
    "projects": "Projects",
    "public_offer": "Public offer",
    "research": "Research",
    "resources": "Resources",
    "services": "Services",
    "sites": "Sites",
    "stay_tuned": "Stay tuned to",
    "support": "Support",
    "task": {
      "buttons": {
        "apply": "Apply",
        "approve": "Approve",
        "assign": "Assign",
        "close": "Close",
        "finish": "Finish",
        "start": "Start"
      },
      "modals": {
        "apply": {
          "description": "Please, input data to apply for task...",
          "header": "Apply to task"
        },
        "assign": {
          "description": "Please, select the member, that you want to assign for this task...",
          "header": "Assign member"
        },
        "close": {
          "description": "Please, input data to close the task...",
          "header": "Close the task",
          "investment": "Make investment"
        }
      },
      "status": {
        "approved": "Approved",
        "archived": "Archived",
        "assigned": "Assigned",
        "closed": "Closed",
        "deleted": "Deleted",
        "evaluated": "Evaluated",
        "finished": "Finished",
        "in_progress": "In progress",
        "opened": "Opened"
      }
    },
    "tasks": "Tasks",
    "terms_of_use": "Terms of use",
    "version": "Version"
  }], ["fr", {}], ["ru", {
    "about_us": "О нас",
    "all_rights_reserved": "Все права защищены",
    "blog": "Блог",
    "business": "Бизнес",
    "careers": "Вакансии",
    "community": "Сообщество",
    "company": "Компания",
    "contacts": "Контакты",
    "design": "Проектирование",
    "development": "Разработка",
    "digitalisation": "Цифровизация",
    "docs": "Документация",
    "events": "События",
    "feedback": "Обратная связь",
    "language": "Язык",
    "languages": "Языки",
    "legal": "Право",
    "mail": "Почта",
    "marketing": "Маркетинг",
    "neuronet": "Нейронет",
    "our_updates": "наших обновлений и специальных предложений",
    "payments": "Платежи",
    "portfolio": {
      "buttons": {
        "view": "View"
      },
      "content": "Content",
      "header": "Header"
    },
    "pricing": "Цены",
    "privavy_policy": "Политика конфиденциальности",
    "products": "Продукты",
    "projects": "Проекты",
    "public_offer": "Публичная оферта",
    "research": "Исследования",
    "resources": "Ресурсы",
    "services": "Услуги",
    "sites": "Сайты",
    "stay_tuned": "Оставайтесь в курсе",
    "support": "Поддержка",
    "tasks": "Задачи",
    "terms_of_use": "Условия использования",
    "version": "Версия"
  }], ["uk", {
    "about_us": "Про нас",
    "all_rights_reserved": "Всі права захищено",
    "blog": "Блог",
    "business": "Бізнес",
    "buttons": {
      "activate": "Активувати",
      "comment": "Коментувати",
      "delete": "Видалити",
      "edit": "Редагувати",
      "subscribe": "Підписатися"
    },
    "careers": "Вакансії",
    "community": "Спільнота",
    "company": "Компанія",
    "contacts": "Контакти",
    "design": "Проектування",
    "development": "Розробка",
    "digitalisation": "Цифровізація",
    "docs": "Документація",
    "entity": {
      "activate_confirm": "Активувати об'єкт \"{title}\" по {date}?",
      "activate_months": "{months, plural, =1 {1 місяць} other {# місяців}}",
      "custom_date": "Довільна дата"
    },
    "error": {
      "occured": "Сталася помилка!"
    },
    "events": "Події",
    "feedback": "Зворотній зв'язок",
    "invoice": {
      "status": {
        "opened": "Відкритий",
        "payed": "Сплачений"
      }
    },
    "language": "Мова",
    "languages": "Мови",
    "legal": "Право",
    "mail": "Пошта",
    "marketing": "Маркетинг",
    "modal": {
      "confirm": "Підтверджуєте операцію?"
    },
    "nav": {
      "sites": "Сайти"
    },
    "neuronet": "Нейронет",
    "order": {
      "status": {
        "accepted": "Виконується",
        "draft": "Нове",
        "finished": "Завершено",
        "opened": "Надходять пропозиції"
      }
    },
    "our_updates": "наших оновлень та спеціальних пропозицій",
    "payments": "Платежі",
    "portfolio": {
      "buttons": {
        "view": "View"
      },
      "content": "Content",
      "header": "Header"
    },
    "pricing": "Ціни",
    "privavy_policy": "Політика конфіденційності",
    "products": "Продукти",
    "projects": "Проекти",
    "public_offer": "Публічна оферта",
    "research": "Дослідження",
    "resources": "Ресурси",
    "services": "Послуги",
    "sites": "Сайти",
    "stay_tuned": "Залишайтеся в курсі",
    "support": "Підтримка",
    "task": {
      "buttons": {
        "apply": "Apply",
        "approve": "Approve",
        "assign": "Assign",
        "close": "Close",
        "finish": "Finish",
        "start": "Start"
      },
      "modals": {
        "apply": {
          "description": "Please, input data to apply for task...",
          "header": "Apply to task"
        },
        "assign": {
          "description": "Please, select the member, that you want to assign for this task...",
          "header": "Assign member"
        },
        "close": {
          "description": "Please, input data to close the task...",
          "header": "Close the task",
          "investment": "Make investment"
        }
      },
      "status": {
        "approved": "Approved",
        "assigned": "Assigned",
        "closed": "Closed",
        "evaluated": "Evaluated",
        "finished": "Finished",
        "in_progress": "In progress",
        "opened": "Opened"
      }
    },
    "tasks": "Задачі",
    "terms_of_use": "Умови використання",
    "version": "Версія"
  }]];
  _exports.default = _default;
});