define("ember-place-autocomplete/services/google-place-autocomplete", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var google = this.google || (window ? window.google : null);
      if (google && document) {
        var googlePlaces = google.maps.places;
        var autocompleteService = new googlePlaces.AutocompleteService();
        var placesService = new googlePlaces.PlacesService(document.createElement('div'));
        var sessionToken = new googlePlaces.AutocompleteSessionToken();
        this.setProperties({
          autocompleteService: autocompleteService,
          google: google,
          sessionToken: sessionToken,
          placesService: placesService
        });
      }
    },
    getPlacePredictions: function getPlacePredictions(properties) {
      var _this = this;
      if (!properties.hasOwnProperty('input')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if (Ember.isBlank(properties.input)) {
        return Ember.RSVP.Promise.resolve([]);
      }
      properties.sessionToken = this.sessionToken;
      return new Ember.RSVP.Promise(function (resolve) {
        _this.autocompleteService.getPlacePredictions(properties, _this._googleResponseCallback.bind(_this, [resolve], []));
      });
    },
    getQueryPredictions: function getQueryPredictions(properties) {
      var _this2 = this;
      if (!properties.hasOwnProperty('input')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if (Ember.isBlank(properties.input)) {
        return Ember.RSVP.Promise.resolve([]);
      }
      return new Ember.RSVP.Promise(function (resolve) {
        _this2.autocompleteService.getQueryPredictions(properties, _this2._googleResponseCallback.bind(_this2, [resolve], []));
      });
    },
    getDetails: function getDetails(request) {
      var _this3 = this;
      request.sessionToken = this.sessionToken;
      if (!request.hasOwnProperty('fields') && !request.hasOwnProperty('placeId')) {
        return Ember.RSVP.Promise.reject(new Error('[service/google-place-autocomplete] getDetails needs the placeId and fields as properties of the request params'));
      }
      this.updateSessionToken();
      return new Ember.RSVP.Promise(function (resolve) {
        _this3.placesService.getDetails(request, _this3._googleResponseCallback.bind(_this3, [resolve], {}));
      });
    },
    _googleResponseCallback: function _googleResponseCallback(promiseCallbacks, failResponseReturnValue, requestResult, status) {
      var google = this.google || (window ? window.google : null);
      var _promiseCallbacks = (0, _slicedToArray2.default)(promiseCallbacks, 1),
        resolve = _promiseCallbacks[0];
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return resolve(requestResult);
      }
      return resolve(failResponseReturnValue);
    },
    updateSessionToken: function updateSessionToken() {
      var googlePlaces = this.google.maps.places;
      this.set('sessionToken', new googlePlaces.AutocompleteSessionToken());
    }
  });
  _exports.default = _default;
});