define("ember-responsive/initializers/responsive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * Ember responsive initializer
   *
   * Supports auto injecting media service app-wide.
   * Generated by the ember-responsive addon.
   */
  function initialize(application) {
    application.registerOptionsForType('breakpoints', {
      instantiate: false
    });
  }
  var _default = {
    name: 'ember-responsive-breakpoints',
    initialize: initialize
  };
  _exports.default = _default;
});