define('ember-hold-button/components/hold-button', ['exports', 'ember-hold-button/templates/components/hold-button'], function (exports, _holdButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var positionalParams = {
    positionalParams: 'params'
  };

  var HoldButtonComponent = Ember.Component.extend(positionalParams, {
    layout: _holdButton.default,
    tagName: 'button',
    classNames: ['ember-hold-button'],
    classNameBindings: ['isHolding', 'isComplete', 'type'],
    attributeBindings: ['style'],

    delay: 500,
    type: 'rectangle',

    timer: null,
    isHolding: false,
    isComplete: false,

    style: Ember.computed('delay', function () {
      var delay = this.get('delay');

      var durations = ['-webkit-transition-duration', '-moz-transition-duration', 'transition-duration', '-webkit-animation-duration', '-moz-animation-duration', 'animation-duration'].map(function (property) {
        return property + ': ' + delay + 'ms';
      }).join(';');

      return Ember.String.htmlSafe(durations);
    }),

    setup: Ember.on('willInsertElement', function () {
      this.registerHandler();
    }),

    registerHandler: function registerHandler() {
      this.on('mouseDown', this, this.startTimer);
      this.on('touchStart', this, this.startTouchTimer);
    },
    startTouchTimer: function startTouchTimer(e) {
      e.stopPropagation();
      e.preventDefault();
      this.startTimer();
    },
    startTimer: function startTimer() {
      if (!this.get('timer')) {
        this.set('isComplete', false);
        this.set('isHolding', true);

        this.off('mouseDown', this, this.startTimer);
        this.off('touchStart', this, this.startTouchTimer);

        this.on('mouseUp', this, this.cancelTimer);
        this.on('mouseLeave', this, this.cancelTimer);
        this.on('touchEnd', this, this.cancelTimer);
        this.on('touchCancel', this, this.cancelTimer);

        var timer = Ember.run.later(this, this.timerFinished, this.get('delay'));
        this.set('timer', timer);
      }
    },
    cancelTimer: function cancelTimer() {
      this.set('isHolding', false);
      Ember.run.cancel(this.get('timer'));
      this.set('timer', null);
      this.off('mouseUp', this, this.cancelTimer);
      this.off('mouseLeave', this, this.cancelTimer);
      this.off('touchEnd', this, this.cancelTimer);
      this.off('touchCancel', this, this.cancelTimer);
      this.registerHandler();
    },
    timerFinished: function timerFinished() {
      if (this.get('isHolding') && !this.get('isComplete')) {
        var params = this.getWithDefault('params', []);
        var actionParams = ['action'].concat(_toConsumableArray(params));
        // eslint-disable-next-line
        this.sendAction.apply(this, _toConsumableArray(actionParams));
        this.set('isComplete', true);
        this.registerHandler();
      }
    }
  });

  HoldButtonComponent.reopenClass(positionalParams);
  exports.default = HoldButtonComponent;
});