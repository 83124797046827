define("ember-bootstrap/templates/components/bs-form/element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5r/AKZCK",
    "block": "{\"symbols\":[\"message\",\"@messages\"],\"statements\":[[6,[37,3],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,0,[\"showMultipleErrors\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pre-scrollable\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[34,0]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[32,0,[\"feedbackClass\"]]],[12],[1,[32,2,[\"firstObject\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"feedbackClass\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/errors.hbs"
    }
  });
  _exports.default = _default;
});