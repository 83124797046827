define("ember-local-settings/adapters/session-storage", ["exports", "ember-local-settings/adapters/web-storage"], function (_exports, _webStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Adapter that stores values in HTML5 local storage.
   *
   * @class SessionStorageAdapter
   * @extends WebStorageAdapter
   */
  var SessionStorageAdapter = _webStorage.default.extend({
    /**
     * @property storageAPI
     * @private
     */
    storageAPI: 'sessionStorage'
  });
  SessionStorageAdapter.reopenClass({
    toString: function toString() {
      return "ember-local-settings/adapters/session-storage";
    }
  });
  var _default = SessionStorageAdapter;
  _exports.default = _default;
});