define('ember-cli-text-support-mixins/mixins/focus-selects-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * If you override make sure to `this._super(...arguments)` to preserve this behaviour.
     */
    focusIn: function focusIn() /*event*/{
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('focusSelectsText?') && this.$().is(':text, textarea')) {
        // using a runloop to make sure textarea text can be selected in webkit/safari
        // @see https://stackoverflow.com/a/6201757/545137
        Ember.run.later(this, function () {
          _this.$().trigger('select');
        }, 1);
      }
    },

    /**
     * By default, focus on `this` input/textarea will select the text within.  Set this to `false` if you do not
     * want this behaviour.
     */
    'focusSelectsText?': true
  });
});