define("ember-local-storage/mixins/storage", ["exports", "ember-local-storage/helpers/storage", "ember-copy"], function (_exports, _storage2, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assignIt = Ember.assign || Ember.merge;
  var _default = Ember.Mixin.create({
    _storageKey: null,
    _initialContent: null,
    _initialContentString: null,
    _isInitialContent: true,
    // we need it for storage event testing
    _testing: false,
    // Shorthand for the storage
    _storage: function _storage() {
      return (0, _storage2.getStorage)(Ember.get(this, '_storageType'));
    },
    init: function init() {
      var storage = this._storage();
      var storageKey = Ember.get(this, '_storageKey');
      var initialContent = Ember.get(this, '_initialContent');
      var serialized, content;
      Ember.set(this, '_initialContentString', JSON.stringify(initialContent));

      // Merge the serialized version into defaults.
      content = this._getInitialContentCopy();

      // Retrieve the serialized version from storage.
      serialized = storage[storageKey];
      if (serialized) {
        assignIt(content, JSON.parse(serialized));
      }

      // Do not change to set(this, 'content', content)
      this.set('content', content);

      // Keep in sync with other windows
      this._addStorageListener();
      return this._super.apply(this, arguments);
    },
    _getInitialContentCopy: function _getInitialContentCopy() {
      var initialContent = Ember.get(this, '_initialContent');
      var content = (0, _emberCopy.copy)(initialContent, true);

      // copy returns a normal array when prototype extensions are off
      // This ensures that we wrap it in an Ember Array.
      return Ember.isArray(content) ? Ember.A(content) : content;
    },
    _addStorageListener: function _addStorageListener() {
      var _this = this;
      var storage = this._storage();
      var storageKey = Ember.get(this, '_storageKey');
      if (window.addEventListener) {
        this._storageEventHandler = function (event) {
          if (_this.isDestroying) {
            return;
          }
          if (event.storageArea === storage && event.key === storageKey) {
            if ('hidden' in document && !document.hidden && !_this._testing || event.newValue === event.oldValue || event.newValue === JSON.stringify(_this.get('content'))) {
              return;
            }
            if (event.newValue) {
              // TODO: Why do we use this.set here? I guess it's the loop bug...
              _this.set('content', JSON.parse(event.newValue));
            } else {
              _this.clear();
            }
          }
        };
        window.addEventListener('storage', this._storageEventHandler, false);
      }
    },
    _save: function _save() {
      var storage = this._storage();
      var content = Ember.get(this, 'content');
      var storageKey = Ember.get(this, '_storageKey');
      var initialContentString = Ember.get(this, '_initialContentString');

      // TODO: Why is it needed?
      if (storageKey) {
        var json = JSON.stringify(content);
        if (json !== initialContentString) {
          Ember.set(this, '_isInitialContent', false);
        }
        storage[storageKey] = json;
      }
    },
    willDestroy: function willDestroy() {
      if (this._storageEventHandler) {
        window.removeEventListener('storage', this._storageEventHandler, false);
      }
      this._super.apply(this, arguments);
    },
    // Public API
    // returns boolean
    isInitialContent: function isInitialContent() {
      return Ember.get(this, '_isInitialContent');
    },
    // reset the content
    // returns void
    reset: function reset() {
      var content = this._getInitialContentCopy();

      // Do not change to set(this, 'content', content)
      this.set('content', content);
      Ember.set(this, '_isInitialContent', true);
    },
    // clear the content
    // returns void
    clear: function clear() {
      this._clear();
      delete this._storage()[Ember.get(this, '_storageKey')];
    }
  });
  _exports.default = _default;
});