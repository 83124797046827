define("ember-composable-helpers/helpers/reverse", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverse = reverse;
  function reverse(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      array = _ref2[0];
    if (!Ember.isArray(array)) {
      return [array];
    }
    return Ember.A(array).slice(0).reverse();
  }
  var _default = Ember.Helper.helper(reverse);
  _exports.default = _default;
});