define("ember-math-helpers/helpers/lcm", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-math-helpers/helpers/gcd"], function (_exports, _slicedToArray2, _gcd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lcm = lcm;
  /**
   * Returns the smallest positive integer that is divisible by both number1 and number2
   *
   * ```hbs
   * {{lcm a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The LCM of the two numbers passed
   */
  function lcm(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      _ref2$ = _ref2[0],
      number1 = _ref2$ === void 0 ? 0 : _ref2$,
      _ref2$2 = _ref2[1],
      number2 = _ref2$2 === void 0 ? 0 : _ref2$2;
    return number1 === 0 || number2 === 0 ? 0 : Math.abs(number1 * number2) / (0, _gcd.gcd)([number1, number2]);
  }
  var _default = Ember.Helper.helper(lcm);
  _exports.default = _default;
});