define("ember-modal-dialog/components/in-place-dialog", ["exports", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computedJoin = function computedJoin(prop) {
    return Ember.computed(prop, function () {
      return this.get(prop).join(' ');
    });
  };
  var _default = Ember.Component.extend({
    tagName: '',
    layout: _inPlaceDialog.default,
    containerClass: null,
    // passed in
    containerClassNames: ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place'],
    // set this in a subclass definition
    containerClassNamesString: computedJoin('containerClassNames'),
    concatenatedProperties: ['containerClassNames']
  });
  _exports.default = _default;
});