define("ember-tag-input/templates/components/tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zrJ1yQ91",
    "block": "{\"symbols\":[\"tag\",\"index\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[14,0,\"emberTagInput-tag\"],[12],[2,\"\\n    \"],[18,3,[[32,1]]],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"emberTagInput-remove\"],[4,[38,0],[[32,0],\"removeTag\",[32,2]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13]],\"parameters\":[1,2]}]]],[10,\"li\"],[14,0,\"emberTagInput-new\"],[12],[2,\"\\n  \"],[1,[30,[36,10],null,[[\"disabled\",\"class\",\"maxlength\",\"placeholder\"],[[35,8],[30,[36,9],[\"emberTagInput-input js-ember-tag-input-new\",[30,[36,2],[[35,8],\" is-disabled\"],null]],null],[35,7],[35,6]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"_isRemoveButtonVisible\",\"if\",\"tags\",\"-track-array\",\"each\",\"placeholder\",\"maxlength\",\"readOnly\",\"concat\",\"input\"]}",
    "meta": {
      "moduleName": "ember-tag-input/templates/components/tag-input.hbs"
    }
  });
  _exports.default = _default;
});