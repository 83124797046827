define("ember-local-settings/serializers", ["exports", "ember-local-settings/serializers/json", "ember-local-settings/serializers/noop"], function (_exports, _json, _noop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    json: _json.default,
    noop: _noop.default
  };
  _exports.default = _default;
});