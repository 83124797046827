define("ember-file-upload/mirage/utils", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-file-upload/system/file-reader"], function (_exports, _slicedToArray2, _fileReader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractFileMetadata = extractFileMetadata;
  _exports.extractFormData = extractFormData;
  function extractFormData(formData) {
    var data = {};
    var items = formData.entries();
    var item = items.next();
    var file = null;
    while (!item.done) {
      var _item$value = (0, _slicedToArray2.default)(item.value, 2),
        key = _item$value[0],
        value = _item$value[1];
      if (value instanceof Blob) {
        file = {
          key: key,
          value: value
        };
      } else {
        data[key] = value;
      }
      item = items.next();
    }
    return {
      file: file,
      data: data
    };
  }
  var pipelines = {
    gif: function gif(file) {
      var reader = new _fileReader.default();
      return reader.readAsArrayBuffer(file).then(function (buffer) {
        var data = new Uint8Array(buffer);
        var duration = 0;
        for (var i = 0; i < data.length; i++) {
          // Find a Graphic Control Extension hex(21F904__ ____ __00)
          if (data[i] === 0x21 && data[i + 1] === 0xF9 && data[i + 2] === 0x04 && data[i + 7] === 0x00) {
            // Swap 5th and 6th bytes to get the delay per frame
            var delay = data[i + 5] << 8 | data[i + 4] & 0xFF;

            // Should be aware browsers have a minimum frame delay
            // e.g. 6ms for IE, 2ms modern browsers (50fps)
            duration += delay < 2 ? 10 : delay;
          }
        }
        return {
          duration: duration / 1000,
          animated: duration > 0
        };
      });
    },
    image: function image(file, metadata) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
          resolve(img);
        };
        img.onerror = function () {
          reject(new Error('You tried to upload an invalid image. The upload handler for mirage ' + 'shipped with ember-file-upload does not support invalid images. ' + 'Please make sure that your image is valid and can be parsed by browsers.'));
        };
        img.src = metadata.url;
      }).then(function (img) {
        return {
          width: img.naturalWidth,
          height: img.naturalHeight
        };
      });
    },
    video: function video(file, metadata) {
      var video = document.createElement('video');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        video.addEventListener('loadeddata', resolve);
        video.onerror = function () {
          reject(new Error('You tried to upload an invalid video. The upload handler for mirage ' + 'shipped with ember-file-upload does not support invalid videos. ' + 'Please make sure that your video is valid and can be parsed by browsers.'));
        };
        video.src = metadata.url;
        document.body.appendChild(video);
        video.load();
      }).then(function () {
        return {
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight
        };
      }).finally(function () {
        document.body.removeChild(video);
      });
    },
    audio: function audio(file, metadata) {
      var audio = document.createElement('audio');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        audio.addEventListener('loadeddata', resolve);
        audio.onerror = function () {
          reject(new Error('You tried to upload an invalid audio file. The upload handler for mirage ' + 'shipped with ember-file-upload does not support invalid audio files. ' + 'Please make sure that your audio is valid and can be parsed by browsers.'));
        };
        audio.src = metadata.url;
        document.body.appendChild(audio);
        audio.load();
      }).then(function () {
        return {
          duration: audio.duration
        };
      }).finally(function () {
        document.body.removeChild(audio);
      });
    }
  };
  function extractFileMetadata(file) {
    var metadata = {
      name: file.name,
      size: file.size,
      type: file.type,
      extension: (file.name.match(/\.(.*)$/) || [])[1]
    };
    var reader = new _fileReader.default();
    return reader.readAsDataURL(file).then(function (url) {
      metadata.url = url;
      var additionalMetadata = [];
      if (metadata.type === 'image/gif') {
        additionalMetadata.push(pipelines.gif(file, metadata));
      }
      if (metadata.type.match(/^image\//)) {
        additionalMetadata.push(pipelines.image(file, metadata));
      }
      if (metadata.type.match(/^video\//)) {
        additionalMetadata.push(pipelines.video(file, metadata));
      }
      if (metadata.type.match(/^audio\//)) {
        additionalMetadata.push(pipelines.audio(file, metadata));
      }
      return Ember.RSVP.all(additionalMetadata);
    }).then(function (additionalMetadata) {
      additionalMetadata.forEach(function (data) {
        Object.assign(metadata, data);
      });
      return metadata;
    });
  }
});