define("ember-model-validator/initializers/register-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    Ember.libraries.register('Ember Model Validator', '3.11.0');
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});