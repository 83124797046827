define("ember-bootstrap/templates/components/bs-form/element/layout/vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gelvo4X2",
    "block": "{\"symbols\":[\"@labelComponent\",\"&default\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,2,null],[2,\"\\n\"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n\"],[1,[30,[36,0],[[32,4]],null]]],\"hasEval\":false,\"upvars\":[\"component\",\"hasLabel\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical.hbs"
    }
  });
  _exports.default = _default;
});