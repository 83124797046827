define('ember-simple-infinite-scroller/services/-infinite-scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    $document: function $document() {
      return Ember.$(document);
    },
    $window: function $window() {
      return Ember.$(window);
    }
  });
});