define("ember-cli-uuid/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULTS = {
    defaultUUID: true
  };

  /**
    Ember CLI UUID's configuration object (borrowed from Ember Simple Auth).
  
    To change any of these values, set them on the application's environment
    object, e.g.:
  
    ```js
    // config/environment.js
    ENV['ember-cli-uuid'] = {
      defaultUUID: false
    };
    ```
  
    @class Configuration
    @extends Object
    @module ember-cli-uuid/configuration
    @public
  */
  var _default = {
    /**
      If `defaultUUID` is set to `true`, all Ember-Data generated records will
      have an automated UUID v4 set as their primary key.
       @property defaultUUID
      @readOnly
      @static
      @type Boolean
      @default false
      @public
    */
    defaultUUID: DEFAULTS.defaultUUID,
    load: function load(config) {
      for (var property in this) {
        if (Object.prototype.hasOwnProperty.call(this, property) && Ember.typeOf(this[property]) !== 'function') {
          this[property] = Ember.getWithDefault(config, property, DEFAULTS[property]);
        }
      }
    }
  };
  _exports.default = _default;
});