define("ember-math-helpers/helpers/clz32", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clz32 = clz32;
  _exports.default = void 0;
  /**
   * Executes `Math.clz32` on the number passed to the helper.
   *
   * ```hbs
   * {{clz32 a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.clz32`
   * @return {number} The clz32 of the passed number
   */
  function clz32(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      number = _ref2[0];
    return Math.clz32(number);
  }
  var _default = Ember.Helper.helper(clz32);
  _exports.default = _default;
});