define("ember-cli-meta-tags/components/head-tags", ["exports", "ember-cli-meta-tags/templates/components/head-tags"], function (_exports, _headTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    headTags: Ember.A([]),
    layout: _headTags.default
  });
  _exports.default = _default;
});