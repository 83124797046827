define('ember-cli-text-support-mixins/mixins/trigger-focus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * @default autofocus is set to false.
     */
    autofocus: false,
    /**
     * When the element is inserted, trigger the focus event using jQuery.
     */
    _triggerFocus: Ember.on('didInsertElement', function () {
      if (this.get('autofocus')) {
        this.$().trigger('focus');
      }
    })
  });
});