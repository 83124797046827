define("ember-bootstrap/templates/components/bs-accordion/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HctOGcsF",
    "block": "{\"symbols\":[\"@class\",\"&default\",\"@collapsed\"],\"statements\":[[6,[37,0],[[32,0,[\"collapseComponent\"]]],[[\"ariaRole\",\"collapsed\"],[\"tabpanel\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"card-body \",[32,1]]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/body.hbs"
    }
  });
  _exports.default = _default;
});