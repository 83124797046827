define("ember-froala-editor/helpers/html-safe", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;
  function htmlSafe(context) {
    for (var _len = arguments.length, partial = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      partial[_key - 1] = arguments[_key];
    }
    // @on-eventName={{html-safe (fn (mut this.content))}}
    if (typeof context === 'function') {
      return function toStringClosure(content) {
        var contentSafeString = Ember.String.isHTMLSafe(content) ? content : Ember.String.htmlSafe(content);
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }
        return context.apply(void 0, [contentSafeString].concat(partial, args));
      };

      // @content={{html-safe this.content}}
    } else {
      return Ember.String.isHTMLSafe(context) ? context : Ember.String.htmlSafe(context);
    }
  }
  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
      context = _ref2[0],
      partial = _ref2.slice(1);
    return htmlSafe.apply(void 0, [context].concat((0, _toConsumableArray2.default)(partial)));
  });
  _exports.default = _default;
});