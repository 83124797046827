define('ember-delay/delay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var delay = function delay(milliseconds) {
    if (milliseconds === undefined) {
      milliseconds = 2000;
    }

    return new Ember.RSVP.Promise(function (resolve) {
      Ember.run.later(this, resolve, milliseconds);
    });
  };

  exports.default = delay;
});