define("ember-file-upload/components/file-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SQrSjCK8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"supported\",\"active\",\"valid\"],[[32,0,[\"supported\"]],[32,0,[\"active\"]],[32,0,[\"valid\"]]]]],[32,0,[\"queue\"]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-dropzone/template.hbs"
    }
  });
  _exports.default = _default;
});