define("ember-cli-uuid/utils/uuid-helpers", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uuid = uuid;
  function uuid() {
    return (0, _uuid.v4)();
  }
  var _default = uuid;
  _exports.default = _default;
});