define("ember-bootstrap/helpers/bs-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bsContains = bsContains;
  _exports.default = void 0;
  function bsContains(params /* , hash*/) {
    return Ember.isArray(params[0]) ? Ember.A(params[0]).includes(params[1]) : false;
  }
  var _default = Ember.Helper.helper(bsContains);
  _exports.default = _default;
});