define("ember-math-helpers/helpers/atan2", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.atan2 = atan2;
  _exports.default = void 0;
  /**
   * Executes `Math.atan2` on the numbers passed to the helper.
   *
   * ```hbs
   * {{atan2 a b}}
   * ```
   *
   * @param {number} number1 The first number to pass to `Math.atan2`
   * @param {number} number2 The second number to pass to `Math.atan2`
   * @return {number} The atan2 of the passed numbers
   */
  function atan2(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      number1 = _ref2[0],
      number2 = _ref2[1];
    return Math.atan2(number1, number2);
  }
  var _default = Ember.Helper.helper(atan2);
  _exports.default = _default;
});