define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Me/qS09w",
    "block": "{\"symbols\":[\"@group\",\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[30,[36,0],[[32,1,[\"disabled\"]],\"true\"],null]],[14,\"role\",\"option\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ember-power-select-group-name\"],[12],[1,[32,1,[\"groupName\"]]],[13],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });
  _exports.default = _default;
});