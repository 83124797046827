define('ember-simple-infinite-scroller/components/infinite-scroller', ['exports', 'ember-simple-infinite-scroller/templates/components/infinite-scroller'], function (exports, _infiniteScroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var round = Math.round;
  exports.default = Ember.Component.extend({
    layout: _infiniteScroller.default,
    classNames: ['infinite-scroller'],
    classNameBindings: ['isLoading'],

    debug: false,

    _infiniteScroller: Ember.inject.service('-infinite-scroller'),

    init: function init() {
      this._super.apply(this, arguments);
      var guid = Ember.guidFor(this);
      this.set('scrollEventName', 'scroll.' + guid);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$scroller().on(this.get('scrollEventName'), function (e) {
        _this.set('_scrollDebounceCancelId', Ember.run.debounce(_this, '_scrollingElement', e, _this._scrollDebounce()));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$scroller().off(this.get('scrollEventName'));
      Ember.run.cancel(this.get('_scrollDebounceCancelId'));
    },
    _scrollDebounce: function _scrollDebounce() {
      return this.get('scroll-debounce') || 100;
    },
    $scroller: function $scroller() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller').$document();
      } else {
        return this.$();
      }
    },
    _scrollerHeight: function _scrollerHeight() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller').$window().height();
      } else {
        return this.$scroller().outerHeight();
      }
    },
    _scrollableHeight: function _scrollableHeight() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller').$document().outerHeight();
      } else {
        return this.get('element').scrollHeight;
      }
    },
    _scrollTop: function _scrollTop() {
      if (this.get('use-document')) {
        return this.get('_infiniteScroller').$document().scrollTop();
      } else {
        return this.$().scrollTop();
      }
    },
    _scrollerBottom: function _scrollerBottom() {
      return this._scrollableHeight() - this._scrollerHeight();
    },
    _scrollPercentage: function _scrollPercentage() {
      return round(this._scrollTop() / this._scrollerBottom() * 100);
    },
    _triggerAt: function _triggerAt() {
      return parseInt(this.get('trigger-at') || '100%', 10);
    },
    _reachedBottom: function _reachedBottom() {
      return this._scrollPercentage() >= this._triggerAt();
    },
    _shouldLoadMore: function _shouldLoadMore() {
      return this._reachedBottom() && !this.get('isLoading');
    },
    _scrollingElement: function _scrollingElement() {
      if (this.get('debug')) {
        this._debug();
      }
      if (this._shouldLoadMore()) {
        this._loadMore();
      }
    },
    _debug: function _debug() {
      /* eslint-disable no-console */
      console.log();
      console.log('scroll debounce', this._scrollDebounce());
      console.log('trigger at', this._triggerAt());
      console.log('scroll percentage', this._scrollPercentage());
      console.log('reached bottom', this._reachedBottom());
      console.log('should load more', this._shouldLoadMore());
    },
    _loadMore: function _loadMore() {
      this.set('error', null);
      this.set('isLoading', true);
      Ember.RSVP.resolve(this.get('on-load-more')()).catch(Ember.run.bind(this, '_loadError')).finally(Ember.run.bind(this, '_loadFinished'));
    },
    _loadError: function _loadError(error) {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('error', error);
    },
    _loadFinished: function _loadFinished() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('isLoading', false);
    },


    actions: {
      loadMore: function loadMore() {
        this._loadMore();
      }
    }
  });
});