define("ember-power-select/helpers/ember-power-select-is-selected", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectIsSelected = emberPowerSelectIsSelected;
  // TODO: Make it private or scoped to the component
  function emberPowerSelectIsSelected(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      option = _ref2[0],
      selected = _ref2[1];
    if (selected === undefined || selected === null) {
      return false;
    }
    if (Ember.isArray(selected)) {
      for (var i = 0; i < selected.length; i++) {
        if (Ember.isEqual(selected[i], option)) {
          return true;
        }
      }
      return false;
    } else {
      return Ember.isEqual(option, selected);
    }
  }
  var _default = Ember.Helper.helper(emberPowerSelectIsSelected);
  _exports.default = _default;
});