define("ember-bootstrap/templates/components/bs-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ja/0qLAr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"item\",\"change\"],[[30,[36,0],[[32,0,[\"itemComponent\"]]],[[\"selected\",\"onClick\"],[[32,0,[\"isSelected\"]],[32,0,[\"doChange\"]]]]],[32,0,[\"doChange\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion.hbs"
    }
  });
  _exports.default = _default;
});