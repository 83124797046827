define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eLCDkU3G",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"input\"],[15,1,[32,0,[\"for\"]]],[15,\"accept\",[32,0,[\"accept\"]]],[15,\"capture\",[32,0,[\"capture\"]]],[15,\"multiple\",[32,0,[\"multiple\"]]],[15,\"disabled\",[32,0,[\"disabled\"]]],[15,\"onchange\",[30,[36,0],[[32,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,1,[[32,0,[\"queue\"]]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });
  _exports.default = _default;
});