define("ember-on-helper/helpers/on-window", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-on-helper/helpers/on"], function (_exports, _toConsumableArray2, _on) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _on.default.extend({
    compute: function compute(positional, named) {
      return this._super([window].concat((0, _toConsumableArray2.default)(positional)), named);
    }
  });
  _exports.default = _default;
});