define("ember-composable-helpers/helpers/range", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/comparison"], function (_exports, _slicedToArray2, _comparison) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.range = range;
  function range(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
      min = _ref2[0],
      max = _ref2[1],
      isInclusive = _ref2[2];
    isInclusive = Ember.typeOf(isInclusive) === 'boolean' ? isInclusive : false;
    var numbers = [];
    if (min < max) {
      var testFn = isInclusive ? _comparison.lte : _comparison.lt;
      for (var i = min; testFn(i, max); i++) {
        numbers.push(i);
      }
    }
    if (min > max) {
      var _testFn = isInclusive ? _comparison.gte : _comparison.gt;
      for (var _i = min; _testFn(_i, max); _i--) {
        numbers.push(_i);
      }
    }
    if (min === max && isInclusive) {
      numbers.push(max);
    }
    return numbers;
  }
  var _default = Ember.Helper.helper(range);
  _exports.default = _default;
});