define("lodash/_root", ["exports", "@babel/runtime/helpers/esm/typeof", "lodash/_freeGlobal"], function (_exports, _typeof2, _freeGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Detect free variable `self`. */
  var freeSelf = (typeof self === "undefined" ? "undefined" : (0, _typeof2.default)(self)) == 'object' && self && self.Object === Object && self;

  /** Used as a reference to the global object. */
  var root = _freeGlobal.default || freeSelf || Function('return this')();
  var _default = root;
  _exports.default = _default;
});