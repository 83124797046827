define("ember-bootstrap/templates/components/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xdunwPbL",
    "block": "{\"symbols\":[\"@inNav\",\"&default\"],\"statements\":[[18,2,[[30,[36,3],null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[30,[36,1],[[32,0,[\"buttonComponent\"]]],[[\"dropdown\",\"isOpen\",\"onClick\",\"onKeyDown\"],[[32,0],[32,0,[\"isOpen\"]],[32,0,[\"toggleDropdown\"]],[32,0,[\"handleKeyEvent\"]]]]],[30,[36,1],[[32,0,[\"toggleComponent\"]]],[[\"dropdown\",\"isOpen\",\"inNav\",\"onClick\",\"onKeyDown\"],[[32,0],[32,0,[\"isOpen\"]],[35,2],[32,0,[\"toggleDropdown\"]],[32,0,[\"handleKeyEvent\"]]]]],[30,[36,1],[[32,0,[\"menuComponent\"]]],[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"dropdownElementId\"],[[32,0,[\"isOpen\"]],[32,0,[\"direction\"]],[32,1],[32,0,[\"toggleElement\"]],[32,0,[\"elementId\"]]]]],[32,0,[\"toggleDropdown\"]],[32,0,[\"openDropdown\"]],[32,0,[\"closeDropdown\"]],[32,0,[\"isOpen\"]]]]]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"keydown\",[32,0,[\"handleKeyEvent\"]]],null]],[2,\"\\n  \"],[1,[30,[36,0],[\"click\",[32,0,[\"closeHandler\"]]],[[\"capture\"],[true]]]],[2,\"\\n  \"],[1,[30,[36,0],[\"keyup\",[32,0,[\"closeHandler\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on-document\",\"component\",\"inNav\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs"
    }
  });
  _exports.default = _default;
});