define("ember-inputmask/components/one-way-number-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_OPTIONS = {
    groupSeparator: ',',
    radixPoint: '.',
    groupSize: '3',
    autoGroup: true
  };

  /**
   * @class OneWayNumberMask
   */
  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('decimal'),
    /**
     * @field mask
     * @override
     */
    mask: 'integer',
    /**
     * Set this to true to include decimals
     *
     * @argument decimal
     * @type Boolean
     */
    decimal: false,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, '_options', Ember.assign({}, Ember.get(this, '_options'), DEFAULT_OPTIONS));
      if (Ember.get(this, 'decimal')) {
        Ember.set(this, 'mask', 'decimal');

        // Give default digits if we don't have them already
        if (Ember.isBlank(Ember.get(this, 'options.digits'))) {
          Ember.set(this, '_options.digits', 2);
        }
      }
    },
    /**
     * sendUpdate - Clean Integer Values
     *
     * @method sendUpdate
     * @override
     * @param  {string} unmaskedValue
     * @param  {string} value
     */
    sendUpdate: function sendUpdate(unmaskedValue, value) {
      if (!this.decimal) {
        unmaskedValue = this._cleanInteger(unmaskedValue);
        value = this._cleanInteger(value);
      }
      Ember.get(this, 'update')(unmaskedValue, value);
    },
    _cleanInteger: function _cleanInteger(number) {
      if (number === '0.') return '';
      return parseInt(number);
    }
  });
  _exports.default = _default;
});