define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gzj6DhyR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,3],[[35,2],\" \",[35,1],\" \",[35,0]],null]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"containerClass\",\"attachmentClass\",\"containerClassNamesString\",\"concat\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });
  _exports.default = _default;
});