define("ember-load/components/ember-load-remover", ["exports", "ember-load/templates/components/ember-load-remover"], function (_exports, _emberLoadRemover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _emberLoadRemover.default,
    'ember-load-config': Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.removeLoadingIndicator();
    },
    /**
     * remove the loading indicator. By default this
     * removes all elements with the '.ember-load-indicator'
     * found CSS class from the DOM
     * @public
     */
    removeLoadingIndicator: function removeLoadingIndicator() {
      var loadingIndicatorClass = this.get('ember-load-config.loadingIndicatorClass') || 'ember-load-indicator';
      var elems = document.querySelectorAll(".".concat(loadingIndicatorClass));
      /**
       * Very important to iterate over the NodeList this way,
       * and remove the DOM elements via removeChild to maintain ie11
       * compatibility
       */
      for (var i = 0; i < elems.length; i++) {
        elems[i].parentNode.removeChild(elems[i]);
      }
    }
  });
  _exports.default = _default;
});