define("ember-inputmask/components/one-way-credit-card-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class OneWayCreditCardMask
   */
  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('separator'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var options = Ember.get(this, '_options');
      Ember.set(this, '_options', Ember.assign({}, {
        // We need to make sure we catch paste events so that we change the mask before the text
        // hits the input. This is a callback provided by Inputmask.js
        onBeforePaste: function onBeforePaste(value) {
          var cardType = _this._determineCardType(value);
          // Set the card type so the parent context can access it
          Ember.set(_this, '_cardType', cardType);
          _this._changeMask();
          Ember.run.scheduleOnce('afterRender', function () {
            // We need to reset the value in case the mask was too small before and characters were
            // cut off
            _this.element.inputmask.setValue(value);

            // We need to update the parent component with the new pasted values
            _this.sendUpdate(_this._getUnmaskedValue(), _this.element.value);
          });
          return value;
        }
      }, options));
    },
    /**
     * _cardType - Use current unmasked value to determine which kind of card the user is
     * typing
     *
     * @computed _cardType
     * @private
     * @return {string} The card type
     */
    _cardType: Ember.computed('_value', {
      get: function get() {
        return this._determineCardType(Ember.get(this, '_value'));
      }
    }),
    /**
     * What kind of separator to use between number sections
     *
     * @argument separator
     * @type String
     */
    separator: '-',
    /**
     * Dynamically determine which mask to use based on what kind of credit card numbers the user
     * is typing
     *
     * @computed mask
     * @override
     */
    mask: Ember.computed('_cardType', {
      get: function get() {
        var cardType = Ember.get(this, '_cardType');
        var s = Ember.get(this, 'separator');
        if (cardType === 'American Express') {
          return "9999".concat(s, "999999").concat(s, "99999");
        }
        if (cardType === 'Diners Club') {
          return "9999".concat(s, "999999").concat(s, "9999");
        }
        return "9999".concat(s, "9999").concat(s, "9999").concat(s, "9999");
      }
    }),
    /**
     * sendUpdate - Pass the credit card type along with the values
     *
     * @method sendUpdate
     * @override
     * @param  {string} unmaskedValue
     * @param  {string} value
     */
    sendUpdate: function sendUpdate(unmaskedValue, value) {
      var cardType = this._determineCardType(unmaskedValue);
      // Set the card type so the parent context can access it
      Ember.set(this, '_cardType', cardType);
      Ember.get(this, 'update')(unmaskedValue, value, cardType);
    },
    /**
     * _determineCardType - Use current unmasked value to determine which kind of card the user is
     * typing
     *
     * @method _determineCardType
     * @private
     * @param {string} unmaskedValue
     * @return {string} The card type
     */
    _determineCardType: function _determineCardType(unmaskedValue) {
      if (Ember.isBlank(unmaskedValue)) {
        return 'Other';
      }
      if (unmaskedValue.match(/^4/)) {
        return 'Visa';
      }
      if (unmaskedValue.match(/^5[1-5]/)) {
        return 'MasterCard';
      }
      if (unmaskedValue.match(/^3[47]/)) {
        return 'American Express';
      }
      if (unmaskedValue.match(/^3(?:0[0-5]|[68])/)) {
        return 'Diners Club';
      }
      if (unmaskedValue.match(/^6(?:011|5)/)) {
        return 'Discover';
      }
      if (unmaskedValue.match(/^(?:2131|1800|35)/)) {
        return 'JCB';
      }
      return 'Other';
    }
  });
  _exports.default = _default;
});