define("ember-keyboard-shortcuts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bindKeyboardShortcuts = bindKeyboardShortcuts;
  _exports.unbindKeyboardShortcuts = unbindKeyboardShortcuts;
  function bindKeyboardShortcuts(context) {
    var shortcuts = Ember.get(context, 'keyboardShortcuts');
    if (Ember.typeOf(shortcuts) !== 'object') {
      return;
    }
    context._mousetraps = [];
    Object.keys(shortcuts).forEach(function (shortcut) {
      var actionObject = shortcuts[shortcut];
      var mousetrap;
      var preventDefault = true;
      function invokeAction(action, eventType) {
        var type = Ember.typeOf(action);
        var callback;
        if (type === 'string') {
          callback = function callback() {
            context.send(action);
            return preventDefault !== true;
          };
        } else if (type === 'function') {
          callback = action.bind(context);
        } else {
          throw new Error('Invalid value for keyboard shortcut: ' + action);
        }
        mousetrap.bind(shortcut, callback, eventType);
      }
      if (Ember.typeOf(actionObject) === 'object') {
        if (actionObject.global === false) {
          mousetrap = new Mousetrap(document);
        } else if (actionObject.scoped) {
          if (Ember.typeOf(actionObject.scoped) === 'boolean') {
            mousetrap = new Mousetrap(Ember.get(context, 'element'));
          } else if (Ember.typeOf(actionObject.scoped) === 'string') {
            mousetrap = new Mousetrap(document.querySelector(actionObject.scoped));
          }
        } else if (actionObject.targetElement) {
          mousetrap = new Mousetrap(actionObject.targetElement);
        } else {
          mousetrap = new Mousetrap(document.body);
        }
        if (actionObject.preventDefault === false) {
          preventDefault = false;
        }
        invokeAction(actionObject.action, actionObject.eventType);
      } else {
        mousetrap = new Mousetrap(document.body);
        invokeAction(actionObject);
      }
      context._mousetraps.push(mousetrap);
    });
  }
  function unbindKeyboardShortcuts(context) {
    var _removeEvent = function _removeEvent(object, type, callback) {
      if (object.removeEventListener) {
        object.removeEventListener(type, callback, false);
        return;
      }
      object.detachEvent('on' + type, callback);
    };
    Array.isArray(context._mousetraps) && context._mousetraps.forEach(function (mousetrap) {
      // manually unbind JS event
      _removeEvent(mousetrap.target, 'keypress', mousetrap._handleKeyEvent);
      _removeEvent(mousetrap.target, 'keydown', mousetrap._handleKeyEvent);
      _removeEvent(mousetrap.target, 'keyup', mousetrap._handleKeyEvent);
      mousetrap.reset();
    });
    context._mousetraps = [];
  }
});