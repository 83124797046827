define("ember-composable-helpers/helpers/union", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "ember-composable-helpers/utils/as-array"], function (_exports, _toConsumableArray2, _toArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.union = union;
  function union(_ref) {
    var _ref3;
    var _ref2 = (0, _toArray2.default)(_ref),
      arrays = _ref2.slice(0);
    var items = (_ref3 = []).concat.apply(_ref3, (0, _toConsumableArray2.default)(arrays));
    return items.filter(function (value, index, array) {
      return (0, _asArray.default)(array).indexOf(value) === index;
    });
  }
  var _default = Ember.Helper.helper(union);
  _exports.default = _default;
});