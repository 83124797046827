define("ember-responsive/helpers/media", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('media').on('mediaChanged', function () {
        _this.recompute();
      });
    },
    media: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        prop = _ref2[0];
      return Ember.get(this, "media.".concat(prop));
    }
  });
  _exports.default = _default;
});