define("lodash/_nodeUtil", ["exports", "@babel/runtime/helpers/esm/typeof", "lodash/_freeGlobal"], function (_exports, _typeof2, _freeGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Detect free variable `exports`. */
  var freeExports = (typeof exports === "undefined" ? "undefined" : (0, _typeof2.default)(exports)) == 'object' && exports && !exports.nodeType && exports;

  /** Detect free variable `module`. */
  var freeModule = freeExports && (typeof module === "undefined" ? "undefined" : (0, _typeof2.default)(module)) == 'object' && module && !module.nodeType && module;

  /** Detect the popular CommonJS extension `module.exports`. */
  var moduleExports = freeModule && freeModule.exports === freeExports;

  /** Detect free variable `process` from Node. */
  var freeProcess = moduleExports && _freeGlobal.default.process;

  /** Used to access faster Node helpers. */
  var nodeUtil = function () {
    try {
      // Use `util.types` for Node 10+.
      var types = freeModule && freeModule.require && freeModule.require('util').types;
      if (types) {
        return types;
      }

      // Legacy `process.binding('util')` for Node < 10.
      return freeProcess && freeProcess.binding && freeProcess.binding('util');
    } catch (e) {}
  }();
  var _default = nodeUtil;
  _exports.default = _default;
});